import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { TaxType, TaxTypeCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<TaxType, TaxTypeCollection>
>;

@Component
export default class TaxtypesMixin extends Mixins(TypedModelMixin) {
  obCollection = new TaxTypeCollection();
  obModelClass = TaxType;
  obCollectionClass = TaxTypeCollection;
  sRoutePath = "/taxtypes";

  created() {
    this.onCreated();
  }
}
